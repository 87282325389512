.Service__Section {
    background-color: #e9abff;
    padding: 100px 0;
}

.Icon__Box {
    border: 2px solid #2047ce;
    color: #232426;
    background-color: #f9f4f1;
    border-radius: 3rem;
    padding: 3rem;
    height: 100%;
}

.Icon__Box .Icon {
    margin-bottom: 2em;
}

.Icon__Box .Title {
    font-size: 1.5rem;
    font-weight: 600;
}


@media only screen and (max-width: 1200px) {
    .Icon__Box {
        border-radius: 2rem;
        padding: 2rem;
    }
    
}