/* --- Feature__Section__CSS --- */
.Feature__Section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.Feature__Section .Tab__Image {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Feature__Section .Tab__Image img {
    width: 450px;
}

.Feature__Section .Tab__List {
    display: flex;
    flex-direction: column;
}


.Feature__Section .Tab__List .Tab__Item {
    margin-bottom: 24px;
    cursor: pointer;
    padding: 24px;
    border-left: 5px solid transparent;
    background-color: transparent;
}


.Feature__Section .Tab__List .Tab__Item .Title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 8px;
}

.Feature__Section .Tab__List .Tab__Item.Active {
    background-color: #f7d7ca;
    border-left-color: #f69870;
}


@media only screen and (max-width: 1200px) {
    .Feature__Section .Tab__List .Tab__Item {
        margin: 0px;
    }

    .Feature__Section .Tab__List .Tab__Item .Title {
        font-size: 20px;
    }
}