.Testimonial__Section {
    padding-bottom: 100px;
}

.Testimonial__Item {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    padding: 2.4rem;
    display: flex;
    background-color: #e9abff;
}

.Header {
    margin-bottom: 12px;
    display: flex;
}

.Photo {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 999px;
    margin: 0;
    margin-right: 16px;
}

.Name {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 5px;
}

.Content {
    font-size: 1.875rem;
    line-height: 2.375rem;
}

.Content strong {
    background-color: #dbc5f6;
    font-weight: 600;
}

.Testimonial__Item.Item2 {
    background-color: #adf0cc;
}

.Testimonial__Item.Item3 {
    background-color: #c1ddee;
}

.Testimonial__Item.Item2 .Content strong {
    background-color: #89c1d1;
}

.Testimonial__Item.Item3 .Content strong {
    background-color: #e8effd;
}