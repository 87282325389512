.Pricing__Item {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 3rem 3rem 2rem;
    display: flex;
    background-color: #f9f4f1;
    border: 2px solid #001b66;
    border-radius: 10px;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    height: 100%;
}

.Name {
    font-weight: 700;
    font-size: 36px;
}

.Amount {
    color: #9451e4;
    font-size: 2.8125rem;
    font-weight: 700;
    line-height: 1.125;
    margin-bottom: 0;
}