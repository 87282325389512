/* --- About__Section__CSS --- */
.About__Section {
    background-color: #001b66;
    color: white;
    padding-top: 100px;
    padding-bottom: 100px;
}

/* --- Left__Icon__Text --- */
.Left__Icon__Text {
    display: flex;
    flex-wrap: wrap;
}

.Left__Icon__Text .Icon {
    width: 60px;
    margin-right: 24px;
}

.Left__Icon__Text .Text {
    width: calc(100% - 84px);
}


@media only screen and (max-width: 480px) {
    .Left__Icon__Text {
        flex-direction: column;
    }

    .Left__Icon__Text .Icon {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .Left__Icon__Text .Text {
        width: 100%;
    }
}