.Product__Section {
    background-image: linear-gradient(to bottom, transparent 50%, #2047ce 50%);
    padding-bottom: 100px;
}

.Product__Item {
    border: 2px solid #2047ce;
    color: #232426;
    background-color: #f9f4f1;
    border-radius: .625rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}


.Product__Item .Title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 2rem;
}

.Product__Item .Desc {
    color: #232426;
    font-size: 23px;
    font-weight: 400;
    margin-bottom: 2rem;
}

.Product__Item .Button {
    margin: auto auto  0 0;
}