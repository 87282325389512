.Testimonial__Section {
    padding: 100px 0px;
    background-color: #adf0cc;
}

.Testimonial__Item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.Testimonial__Image {
    display: inline-flex;
    line-height: 1em;
    width: 180px;
    position: relative;
    margin-right: 25px;
    padding-right: 25px;
}

.Testimonial__Image img {
    border-radius: 999px;
    border: 3px solid #2047ce;
}

.Testimonial__Content {
    width: calc(100% - 230px);
}

.Testimonial__Image:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #2047ce;
    display: block;
}

.Description {
    font-size: 32px;
    line-height: 1.4em;
    font-weight: 300;
    margin-bottom: 1.5em;
}

.Description strong {
    background-color: #89C1D1;
    font-weight: 600;
}

.Name {
    font-size: 1.2em;
}


@media only screen and (max-width: 1200px) {

    .Testimonial__Item {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .Testimonial__Image {
        margin: 0 0 20px 0;
        padding: 0;
        width: 120px;
    }

    .Testimonial__Image:after {
        display: none;
    }

    .Testimonial__Content {
        width: 100%;
    }

    .Description {
        font-size: 20px;
    }

    .Name {
        font-size: 1em;
        line-height: 1em;
    }


}