.Footer__Section {
    background-color: #001b66;
    color: white;
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 14px;
}

.Footer__Section a {
    color: white;
}

.Footer__Section a:hover {
    text-decoration: underline;
}

.Social__Links {
    display: flex;
    flex-wrap: wrap;
    margin: -6px;
}

.Social__Links a {
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 99px;
    margin: 6px;
}

.Social__Links a:hover {
    background-color: white;
    color: #001b66;
}

.Widget__Title {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 24px;
}

.Widget__List {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.Widget__List li:not(:last-child) {
    margin-bottom: 12px;
}

.Inline__List {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -4px -16px;
    padding: 0;
}

.Inline__List li {
    margin: 4px 16px;
}

.Footer__Line {
    background-color: white;
    height: 2px;
    margin: 40px 0;
}