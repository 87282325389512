/* ---Header__Section__CSS */

.Header__Section {
    padding-top: 150px;
    padding-bottom: 100px;
    overflow: hidden;
    width: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
}

.Header__Section .Main__Title {
    font-size: 56px;
    margin-bottom: 32px;
    color: var(--primary-color);
}

.Header__Section .Description {
    font-size: 1.2em;
    line-height: 1.6em;
    margin-bottom: 32px;
}

.Header__Section .Header__Image {
    margin: 0;
    margin-right: -100px;
    margin-left: -100px;
}

.Header__Section .Header__Image img {
    width: 100%;
}


@media only screen and (max-width: 1200px) {
    .Header__Section .Header__Image {
        margin: 0;
    }

    .Header__Section .Main__Title {
        font-size: 42px;
        margin-bottom: 24px;
    }

    .Header__Section .Description {
        font-size: 1em;
    }
}

@media only screen and (max-width: 992px) {
    .Header__Section {
        padding-top: 120px;
    }
    .Header__Section .Main__Title {
        font-size: 32px;
        margin-bottom: 24px;
    }

    .Header__Section .Description {
        font-size: 1em;
    }
}