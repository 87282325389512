.Fees__Section {
    padding: 100px 0;
}

.Fees__Card {
    background-color: white;
    border: 2px solid #001b66;
    border-radius: 1em;
}

.Verticle__Line {
    background-color: #001b66;
    width: 1px;
    height: 100%;
    display: inline-block;
}

.Horizontal__Line {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    z-index: 1;
}

.Horizontal__Line::after {
    content: "";
    background-color: #001b66;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    z-index: -1;
}

.Horizontal__Line span {
    background-color: white;
    padding: 5px 10px;
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
}

.Badge__Title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 1.4em;
}

.Badge__Title .Badge {
    background-color: #b4c2cb;
    padding: 7px 14px;
    text-transform: uppercase;
    border-radius: .2em;
    margin-right: .2em;
    display: inline-block;
}


.Badge__Title.Pro {
    color: #0766d4;
}

.Badge__Title.Pro .Badge {
    background-color: #0766d4;
    color: white;
}

.Recomand__Badge {
    float: right;
    color: #001b66;
    letter-spacing: .32px;
    text-transform: capitalize;
    background-color: #f1bca6;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 500;
    padding: 6px 8px;
    border-radius: 5px;
    line-height: 1;
}

@media only screen and (max-width: 992px) {
    .Verticle__Line {
        width: 100%;
        height: 1px;
        display: block;
        margin: 14px 0 20px;
    }

    .Badge__Title {
        font-size: 18px;
    }

    .Fees__Section h4 {
        font-size: 22px;
    }
}