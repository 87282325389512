/* --- Customer__Section__CSS --- */
.Customer__Section {
    padding-bottom: 120px;
}

.Customer__Section .Customer__Grid {
    border-top: 2px solid #2047ce;
    border-right: 2px solid #2047ce;
}

.Customer__Section .Customer__Grid .Grid__Block {
    min-height: 22rem;
    padding: 2.5rem;
    border-bottom: 2px solid #2047ce;
    border-left: 2px solid #2047ce;
}

.Customer__Section .Customer__Grid .Grid__Block:not(:first-child) {
    grid-column-gap: 0px;
    grid-row-gap: 1rem;
    flex-direction: column;
    grid-template-rows: auto minmax(auto, 1fr) auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    align-content: center;
    display: grid;
}


.Customer__Section .Customer__Grid .Grid__Block .Block__Title {
    letter-spacing: .05em;
    font-size: 1.125rem;
    text-transform: uppercase;
}


@media only screen and (max-width: 992px) {
    .Customer__Section .Customer__Grid .Grid__Block {
        padding: 1.8rem;
        border-bottom: 2px solid #2047ce;
        border-left: 2px solid #2047ce;
    }

    .Heading__Title {
        font-size: 24px;
    }

    .Customer__Section .Customer__Grid .Grid__Block .Block__Title {
        letter-spacing: .02em;
        font-size: 1rem;
    }
}