/*--------------------------
=======CSS Indexing=======
--------------------------
1. Reset-CSS
2. Input-Box-Style
3. Posts and pages
4. Comment-Style
5. Media-Style
6. Alignment-Style
7. WordPress-Post-Caption-Style
8. Text meant only for screen readers.
9. Post-Gallery-Style
----------------------------*/


/* --- Google-Fonts-CSS-CDN --- */
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap');

/*----- 1. Reset_CSS -----*/
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    outline: none;
}

html,
body {
    min-height: 100%;
    width: 100%;
}

body {
    font-family: "Wix Madefor Text", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6em;
    color: var(--text-color);
    background-color: white;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    background-color: #f9f4f1;
}

a {
    outline: none;
    text-decoration: none;
    color: var(--primary-color);
    transition: .3s;
}

a:hover,
a:focus {
    outline: none;
    text-decoration: none;
    color: var(--accent-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Wix Madefor Text", sans-serif;
    font-weight: 300;
    line-height: 1.3em;
    color: #232426;
    margin: 0 0 16px;
    margin-top: -0.2em;
}

.h1,
h1 {
    font-size: 3.815em;
    line-height: 1.2em;
}

.h2,
h2 {
    font-size: 3.052em;
    line-height: 1.3em;
}

.h3,
h3 {
    font-size: 2.441em;
    line-height: 1.3em;
}

.h4,
h4 {
    font-size: 1.953rem;
    line-height: 1.3em;
}

.h5,
h5 {
    font-size: 1.563rem;
    line-height: 1.3em;
}

.h6,
h6 {
    font-size: 1.25rem;
    line-height: 1.4em;
}

small {
    font-size: .75em;
    line-height: 1.2em;
}

sup,
sub {
    font-size: .563em;
    line-height: 1.1em;
}

h3 a,
h4 a,
h5 a,
h6 a,
h3,
h4,
h5,
h6 {
    line-height: 1.4em;
}

input:focus,
button:focus,
a:focus,
a:hover {
    text-decoration: none;
    outline: none;
}

/* Remove Chrome Input Field's Unwanted Yellow Background Color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

p {
    margin: 0 0 15px;
}

p:last-child {
    margin-bottom: 0px;
}


input:focus,
button:focus,
a:focus,
a:hover {
    text-decoration: none;
    outline: none;
}


strong {
    font-weight: 700;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

b,
strong {
    font-weight: 700;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: .7em;
}

big {
    font-size: 1.4em;
}

sub,
sup {
    font-size: .7em;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

svg,
img {
    border: 0;
    max-width: 100%;
    height: auto;
}

svg:not(:root) {
    overflow: hidden;
}

pre {
    display: block;
    font-family: "Courier 10 Pitch", Courier, monospace;
    padding: 30px;
    margin: 0 0 15px;
    line-height: 1.6em;
    font-size: 14px;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    overflow: auto;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 8px 24px 0 var(--border-color);
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: not-allowed;
    opacity: .5;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    border: 1px solid var(--border-color);
    margin: 0 2px;
    padding: 30px;
    border-radius: 6px;
}

fieldset legend {
    border: 0;
    padding: 0;
    font-family: "Wix Madefor Text", sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1.4em;
}

textarea {
    overflow: auto;
    height: 160px;
}

optgroup {
    font-weight: bold;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

blockquote {
    padding: 30px !important;
    border-radius: 6px;
    color: var(--text-color);
    position: relative;
    text-align: left;
    margin-bottom: 30px;
    background-color: var(--secondary-color);
    border-color: var(--border-color);
    font-size: 1.4em;
    line-height: 1.6em;
    overflow: hidden;
    z-index: 1;
}


blockquote:before {
    content: "\f10e";
    font-family: "Font Awesome";
    color: var(--accent-color) !important;
    font-weight: 300;
    font-size: 2em;
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: -1;
}

.wp-block-quote.has-text-align-right:before {
    right: auto;
    left: 16px;
}


blockquote blockquote:after,
blockquote blockquote:before {
    display: none;
}

blockquote blockquote {
    padding: 0 !important;
    border-radius: 0;
    margin-bottom: 0;
    background: none;
    border: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.link_post blockquote {
    width: 100%;
}

.link_post blockquote:before {
    content: "\f0c1";

}

blockquote *:last-child {
    margin-bottom: 0px;
}

blockquote a,
blockquote cite {
    text-decoration: none;
    color: var(--text-color);
    font-style: normal;
}

blockquote a:hover {
    color: var(--accent-color);
    text-decoration: underline;
}

address {
    margin: 0 0 1.5em;
    line-height: inherit;
}

code,
kbd,
tt,
var {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    padding-right: 10px;
    padding-left: 10px;
    display: inline-block;
}

abbr,
acronym {
    border-bottom: 1px dotted var(--border-color);
    cursor: help;
}

mark,
ins {
    background: white;
    text-decoration: none;
    padding: 0 4px;
    border-radius: 2px;
}

hr {
    background-color: var(--border-color);
    opacity: 1;
    border: 0;
    height: 1px;
}

table,
table th,
table td {
    border: 1px solid var(--border-color);
}

dd,
dt {
    line-height: inherit;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0 1.5em 1.5em;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 1.5em;
    width: 100%;
}

td,
th {
    padding: 6px 16px;
}

th {
    font-weight: 700;
    color: var(--primary-color);
}

/*-- 2. Input-Box-Style --*/
.form-control,
select,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    border: 1px solid var(--border-color);
    color: var(--text-color);
    padding: 16px 18px;
    background-color: white;
    width: 100%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 1em;
    border-radius: 6px;
    outline: none;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
    border-color: var(--accent-color);
    box-shadow: none;
    outline: none;
}

select option {
    color: var(--primary-color);
    background: #ffffff;
}

select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    background-image: url('https://www.svgrepo.com/show/12644/arrow-down-angle.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center right 15px;
    padding-right: 30px !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #c5c5c5;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #c5c5c5;
}

input::placeholder,
textarea::placeholder {
    color: #c5c5c5;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-weight: 500;
    outline: none;
}

textarea {
    width: 100%;
}

/*-- 3. Posts and pages --*/
.sticky {
    display: block;
}

.updated:not(.published) {
    display: none;
}

.page-content,
.entry-content,
.entry-summary {
    margin: 1.5em 0 0;
}

.page-links {
    clear: both;
    margin: 0 0 1.5em;
}

/*-- 4. Comment-Style --*/
.comment-content a {
    word-wrap: break-word;
}

.bypostauthor {
    display: block;
}

/*-- 5. Media-Style --*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

iframe {
    width: 100%;
}

iframe,
object {
    max-width: 100%;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #EBECEF;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #B4B8C4;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #B4B8C4;
}