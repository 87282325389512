

/* --- Faq__Section__CSS --- */
.Faq__Section {
    padding-bottom: 100px;
    padding-top: 100px;
    background-color: #e9abff;
}

.Accordion__Items {
    display: block;
}

.Accordion__Item {
    border-bottom: 1px solid #0000001d;
}

.Accordion__Item .Accordion__Title {
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 28px 0;
}


.Accordion__Item:first-child .Accordion__Title {
    padding-top: 0;
}

.Accordion__Item .Accordion__Title::after {
    content: "\f055";
    font-weight: 400;
    font-family: 'Font Awesome 6 Pro';
    margin-left: 24px;
}

.Accordion__Item .Accordion__Title.Active::after {
    content: "\f056";
}

.Accordion__Content p {
    padding-bottom: 24px;
}


@media only screen and (max-width: 1200px) {
    .Accordion__Item .Accordion__Title {
        font-size: 18px;
        padding: 14px 0px;
    }

}