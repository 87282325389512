.Navbar__Wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    min-height: 100px;
    display: flex;
    align-items: center;
    transition: .3s;
}

.Navbar__Wrapper.Sticky {
    position: fixed;
    background-color: white;
    min-height: 70px;
}

.Navbar__Wrapper .Nav__Logo {
    width: 150px;
    display: inline-flex;
    align-items: center;
    min-height: 100px;
    transition: .3s;
}

.Navbar__Wrapper.Sticky .Nav__Logo {
    min-height: 70px;
}

.Navbar__Wrapper .Nav__Menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Navbar__Wrapper .Nav__Menu ul li {
    position: relative;
    display: flex;
    align-items: center;
}

.Navbar__Wrapper .Nav__Menu ul li .Nav__Link {
    color: var(--primary-color);
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
}

.Navbar__Wrapper .Nav__Menu ul li>* {
    transition: .3s;
}

.Navbar__Wrapper .Nav__Menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Navbar__Wrapper .Nav__Menu .Nav__List {
    display: flex;

}

.Navbar__Wrapper .Nav__Menu .Nav__List>li {
    padding: 12px 24px;
}

.Navbar__Wrapper .Nav__Menu .Nav__List .Sub__Toggle {
    border: none;
    background: none;
    padding: 0;
    margin-left: 10px;
    transform: rotate(0deg);
}

.Navbar__Wrapper .Nav__Menu .Nav__List .Sub__Toggle i {
    transform: translateY(3px);
}

.Navbar__Wrapper .Nav__Divider {
    display: inline-block;
    width: 1px;
    height: 24px;
    background-color: #d8d8d8;
}

/* --- Nav__Button --- */
.Navbar__Wrapper .Nav__Button {
    margin-left: 24px;
}

.Navbar__Toggle {
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b2c3d3;
    background-color: white;
    color: var(--primary-color);
    border-radius: .5rem;
    font-size: 1.2em;
}

.Navbar__Wrapper .Nav__Menu ul ul li .Nav__Link {
    color: var(--primary-color);
    font-size: .9em;
    padding: .6em 0;
}


.Navbar__Wrapper .Nav__Menu ul ul li.Active .Nav__Link {
    color: var(--accent-color);
}

.Navbar__Wrapper .Nav__Menu ul ul li:not(:last-child) {
    border-bottom: .5px solid #b2c3d3;
}


@media only screen and (min-width: 993px) {
    .Navbar__Wrapper .Nav__Menu ul ul {
        position: absolute;
        right: -3rem;
        top: 100%;
        width: 15rem;
        min-width: 140px;
        opacity: 0;
        visibility: hidden;
        transition: .1s;
        transform: scale(.9);
        transform-origin: top center;
        box-shadow: -2px 8px 30px -10px rgba(77, 101, 117, .25);
        border: 1px solid #b2c3d3;
        background-color: #f9f4f1;
        border-radius: .5rem;
        padding: .5rem 2rem 1rem;
        overflow: hidden;
    }

    .Navbar__Wrapper .Nav__Menu li:hover>ul {
        opacity: 1;
        visibility: visible;
        transition: .3s;
        transform: scale(1);
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List li:hover>.Sub__Toggle {
        transform: rotate(180deg);
    }

    .Navbar__Wrapper .Nav__Menu ul li.Active > *,
    .Navbar__Wrapper .Nav__Menu ul li:hover>* {
        color: var(--accent-color);
    }
}

@media only screen and (max-width: 992px) {
    .Navbar__Wrapper .Nav__Menu {
        min-width: 200px;
        border: 1px solid #b2c3d3;
        background-color: white;
        border-radius: .5rem;
        padding: 1.2em 2em 2em;
        overflow: hidden;
        box-shadow: -2px 8px 30px -10px rgba(77, 101, 117, .25);
    }

    .Navbar__Wrapper .Nav__Menu,
    .Navbar__Wrapper .Nav__Menu .Nav__List {
        flex-direction: column;
        align-items: flex-start;
    }

    .Navbar__Wrapper .Nav__Menu ul ul {
        margin-top: 10px;
        background-color: white;
    }

    .Navbar__Wrapper .Nav__Divider {
        width: 100%;
        height: 2px;
        margin: 1rem 0;
    }

    .Navbar__Wrapper .Nav__Button {
        margin-left: 0;
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List {
        width: 100%;
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List>li {
        padding: 8px 0px;
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List li {
        flex-wrap: wrap;
        width: 100%;
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List ul {
        width: 100%;
        padding-left: 1.5rem;
        display: none;
        margin-top: 0;
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List .Sub__Toggle {
        margin-left: auto;
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List .Sub__Toggle.Active {
        transform: rotate(180deg);
    }

    .Navbar__Wrapper .Nav__Menu .Nav__List .Sub__Toggle.Active ~ ul {
        color: var(--accent-color);
        display: block;
    }

}