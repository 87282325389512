.Service__Section {
    padding: 100px 0;
    background-color: white;
}

.Service__Item {
    text-align: center;
    padding: 0 15px;
}

.Service__Item .Title {
    font-weight: 600;
}

.Service__Item .Icon {
    margin-bottom: 24px;
    height: 130px;
}