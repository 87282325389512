@media only screen and (min-width: 1400px) {
    .container.medium {
        max-width: 1100px;
    }
}


@media only screen and (max-width: 1200px) {

    /* --- Primary__Button --- */
    .Primary__Button {
        padding: 10px 24px;
        font-size: .9em;
    }

    /* --- Blue__Button --- */
    .Blue__Button {
        padding: 10px 24px;
        font-size: .9em;
    }

    /* --- Lilac__Button --- */
    .Lilac__Button {
        padding: 10px 24px;
        font-size: .9em;
    }

    .Section__Title {
        margin-bottom: 60px;
    }

    .Section__Title .Title {
        font-size: 36px;
        line-height: 1.2em;
        margin-bottom: 16px;
    }

    .Section__Title .Description {
        font-size: 1em;
    }

}

@media only screen and (max-width: 992px) {
    .Section__Title .Title {
        font-size: 32px;
    }

    body {
        font-size: 16px;
    }

    .Inline__Check__List {
        margin: -2px -6px;
    }

    .Inline__Check__List li {
        margin: 2px 6px;
    }

    /* --- Section__Title --- */
    .Section__Title {
        margin-bottom: 30px;
    }
}