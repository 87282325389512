.Promo__Section {
    padding: 100px 0;
}

.Promo__Box {
    border: 3px solid #f69870;
    padding: 5rem;
    position: relative;
    z-index: 1;
}

.Promo__Box:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 3px solid #f69870;
    z-index: -1;
}

.Promo__Title {
    margin: 0;
    font-weight: 600;
    font-size: 34px;
}

@media only screen and (max-width: 1200px) {
    .Promo__Box {
        padding: 3rem;
    }

    .Promo__Title {
        font-size: 24px;
    }

    .Promo__Box:before {
        left: 10px;
        top: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }
}