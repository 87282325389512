.Service__Section {
    background-color: white;
    padding-bottom: 100px;
    padding-top: 100px;
}

.Service__Item {
    border: 2px solid #80BBFF;
    color: #232426;
    background-color: #ffffff;
    border-radius: .625rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Service__Item .Icon {
    margin-bottom: 1.8em;
}

.Service__Item .Title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1.2rem;
}

.Service__Item .Desc {
    color: #232426;
}

.Service__Item .Button {
    margin: auto auto  0 0;
}