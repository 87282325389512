

:root {
    --primary-color: #00204b;
    --text-color: #212529;
    --accent-color: #136acd;
    --border-color: #ededed;
  }
  
  /* --- Bootstrap-Reset-CSS --- */
  .no-scroll {
    overflow: hidden !important;
  }

  /* --- Helper__Class --- */
  .Navbar__Height {
    height: 100px;
    width: 100%;
  }
  
  /* --- Primary__Button --- */
  .Primary__Button {
    padding: 16px 32px;
    display: inline-block;
    background-color: #f69870;
    color: var(--primary-color);
    font-weight: 500;
    border-radius: 99px;
    transition: .3s;
    border: none;
  }
  
  .Primary__Button:hover {
    background-color: #e28c67;
    color: var(--primary-color);
  }
  
  
  /* --- Blue__Button --- */
  .Blue__Button {
    padding: 16px 32px;
    display: inline-block;
    background-color: rgb(32, 71, 206);
    color: white;
    font-weight: 500;
    border-radius: 99px;
    transition: .3s;
    border: none;
    text-align: center;
  }
  
  .Blue__Button:hover {
    background-color: rgb(26, 60, 183);
    color: white;
  }
  
  .Blue__Button.Outline {
    background-color: transparent;
    box-shadow: 0 0 0 2px rgb(32, 71, 206) inset;
    color: rgb(32, 71, 206);
  }
  
  .Blue__Button.Outline:hover {
    color: white;
    background-color: rgb(32, 71, 206);
  }
  
  /* --- Lilac__Button --- */
  .Lilac__Button {
    padding: 16px 32px;
    display: inline-block;
    background-color: #e9abff;
    color: var(--primary-color);
    font-weight: 500;
    border-radius: 99px;
    transition: .3s;
    border: none;
  }
  
  .Lilac__Button:hover {
    background-color: #c997db;
    color: var(--primary-color);
  }
  
  /* --- Text__Icon__Button --- */
  .Text__Icon__Button {
    border: none;
    background: none;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    color: #001b66;
    font-weight: 500;
  }
  
  .Text__Icon__Button:hover {
    color: #001b66;
  }
  
  .Text__Icon__Button .Icon {
    width: 56px;
    height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 2px solid #ededed;
    border-radius: 99px;
  }
  
  .Text__Icon__Button .Text {
    margin-right: 10px;
  }
  
  /* --- Link__Text --- */
  .Link__Text {
    color: #0766d4;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
  
  .Link__Text:hover {
    color: #001b66;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
  
  /* --- Text__Color__Classes --- */
  .Text__Blue {
    color: #0766d4;
  }
  
  .Text__Gray {
    color: #68757d;
    font-size: 14px;
    line-height: 1.4em;
  }
  
  .Text__Gray a {
    font-weight: bold;
    color: #001b66;
  }
  
  .Text__Gray a:hover {
    color: var(--accent-color);
  }
  
  .Inline__Check__List {
    display: inline-flex;
    flex-wrap: wrap;
    margin: -6px -12px;
    list-style: none;
    padding: 0;
    line-height: 1.4em;
  }
  
  .Inline__Check__List li {
    margin: 6px 12px;
  }
  
  .Inline__Check__List li {
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  
  .Check__Text:before,
  .Inline__Check__List li:before {
    content: "\f00c";
    font-family: "Font Awesome 6 Pro";
    margin-right: 16px;
  }

  .Check__Text {
    position: relative;
    padding-left: 28px;
  }

  .Check__Text::before {
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .Reset__List {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .Reset__List li {
    margin: 0 0 .5em 0;
    line-height: 1em;
  }
  
  /* --- Background__Classes --- */
  .BG__Orchid {
    background-color: #9a9ef5;
  }
  
  /* --- Section__Title --- */
  .Section__Title {
    margin-bottom: 80px;
    text-align: center;
  }
  
  .Section__Title .Title {
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .Section__Title .Description {
    font-size: 1.1em;
    line-height: 1.7em;
  }
  