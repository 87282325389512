.Loader__Wrapper {
    text-align: center;
    padding: 50px 30px;
    width: 100%;
}

.Loader__Icon {
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 99px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Loader__Icon::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #e7e9f2;
    /* Border color */
    border-radius: 50%;
    /* Makes it round */
    border-top-color: #2047ce;
    /* Color of the top border */
    animation: spin 1s linear infinite;
    /* Animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Loader__Icon img {
    width: 50%;
    margin-right: -5px;
}