.Form__Actions {
    display: inline-flex;
    flex-wrap: wrap;
    margin: -8px;
}

.Devider {
    background-color: var(--primary-color);
    width: 1px;
}

.Form__Actions>* {
    margin: 8px;
}


@media only screen and (max-width: 992px) {
    .Header__Section {
        padding-top: 120px;
    }

    .Header__Section .Main__Title {
        font-size: 32px;
        margin-bottom: 24px;
    }

    .Header__Section .Description {
        font-size: 1em;
    }

    .Form__Actions {
        justify-content: center;
    }

    .Form__Actions {
        margin: -5px;
    }
    
    .Form__Actions>* {
        margin: 5px;
    }
    
}