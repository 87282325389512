.Plans__Section {
    padding: 100px 0;
}

.Price__Tabs {
    display: inline-flex;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid #001b66;
    border-radius: .6em;
    position: relative;
    z-index: 1;
}

.Price__Tabs .Price__Tab {
    border: none;
    background: none;
    margin: 5px;
    border-radius: .4em;
    padding: 12px 24px;
    line-height: 1.2em;
    min-width: 200px;
    font-weight: 300;
    position: relative;
    transition: .3s;
}

.Price__Tabs .Price__Tab.Active__Monthly,
.Price__Tabs .Price__Tab.Active__Annual {
    color: white;
}

.Price__Tabs .Price__Tab:first-child::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #2047ce;
    display: block;
    border-radius: .4em;
    z-index: -1;
    transition: .3s;
}


.Price__Tabs .Price__Tab:not(.Active__Monthly)::after {
    left: calc(100% + 10px);
}

.Price__Tabs .Price__Tab .Discount__Badge {
    content: attr(data-discount);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 28px);
    box-shadow: 0 0 3px 2px #adf0cc;
    background-color: #adf0cc;
    color: #14713f;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 12px;
    border-radius: .4em;
    pointer-events: none;
    white-space: nowrap;
}

.Price__Tabs .Price__Tab .Discount__Badge img {
    position: absolute;
    right: 110%;
    top: 10px;
}

.Price__Table {
    background-color: white;
    border-radius: 1.2em;
    height: 100%;
    position: relative;
}

.Price__Table .Price__Header {
    padding: 1.5em;
    background-color: #ecf0f3;
    border-radius: 1.2em 1.2em 0 0;
    min-height: 25rem;
    display: flex;
    flex-direction: column;
}

.Pro.Price__Table .Price__Header {
    background-color: #e6f1fe;
}

.Price__Table .Price__Header a:last-child {
    margin-top: auto;
}

.Price__Table .Price__Header .Plan__Name {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 1.4em;
}

.Pro.Price__Table .Price__Header .Plan__Name {
    color: #0766d4;
}

.Price__Table .Price__Header .Plan__Name .Badge {
    background-color: #b4c2cb;
    padding: 7px 14px;
    text-transform: uppercase;
    border-radius: .2em;
    margin-right: .2em;
    display: inline-block;
}

.Pro.Price__Table .Price__Header .Plan__Name .Badge {
    background-color: #0766d4;
    color: white;
}

.Price__Table .Short__Desc {
    margin-bottom: 2em;
}

.Price__Table .Price__Amount {
    font-weight: 600;
    min-height: 2.6em;
    line-height: 1em;
    font-size: 36px;
}

.Pro.Price__Table .Price__Amount {
    color: #0766d4;
}

.Price__Table .Price__Amount .Label {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4em;
}

.Price__Table .Period {
    font-size: 20px;
    font-weight: 500;
}

.Price__Table .Price__Content {
    padding: 1.5em;
}

.Price__Table .Price__Header .Recomand__Badge {
    float: right;
    color: #001b66;
    letter-spacing: .32px;
    text-transform: capitalize;
    background-color: #f1bca6;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 500;
    padding: 6px 8px;
    border-radius: 5px;
    line-height: 1;

    position: absolute;
    right: 22px;
    top: -15px;
}

.Pro.Price__Table .Price__Content [class='Check__Text']:before {
    color: #0766d4;
}

@media only screen and (max-width: 1200px) {
    .Price__Table .Price__Header {
        min-height: 20rem;
    }
}

@media only screen and (max-width: 992px) {
    .Price__Tabs .Price__Tab {
        min-width: 120px;
    }

    .Price__Tabs .Price__Tab .Discount__Badge {
        left: 70%;
    }

    .Price__Table .Price__Header .Plan__Name {
        margin-bottom: .8em;
    }

    .Price__Table .Price__Amount {
        min-height: 2em;
    }

    .Price__Table .Price__Header .Plan__Name {
        font-size: 18px;
    }
}
@media only screen and (max-width: 768px) {
    .Price__Table .Price__Header {
        min-height: inherit;
    }
}