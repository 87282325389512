.Header__Section {
    padding-bottom: 100px;
}

.Privacy__Page__Section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.Page__Title {
    color: #001b66;
    font-size: 60px;
}

.Sub__Title {
    font-weight: 500;
    font-size: 18px;
    color: #7a7b7d;
}

.Privacy__Page__Section table th,
.Privacy__Page__Section table td {
    padding: 8px 18px;
}