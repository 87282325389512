.Header__Section {
    padding-bottom: 100px;
}

.Terms__Page__Section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.Page__Title {
    color: #001b66;
    font-size: 60px;
}

.Terms__Page__Section table th,
.Terms__Page__Section table td {
    padding: 8px 18px;
}