
/* --- CallToAction__Section__CSS --- */
.CallToAction__Section {
    background-image: linear-gradient(to bottom, #e9abff 50%, #001b66 50%);
}

.CallToAction__Section .Card {
    border: 3px solid var(--primary-color);
    padding: 40px;
    background-color: white;
}

.CallToAction__Section .Card .Title {
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 16px;
    font-size: 38px;
}

.CallToAction__Section .Card .Description {
    margin-bottom: 24px;
    font-size: 1.2em;
    line-height: 1.6em;
}


@media only screen and (max-width: 1200px) {
    .CallToAction__Section .Card .Title {
        font-size: 28px;
    }
}