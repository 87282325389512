/* --- Service__Section__CSS --- */
.Service__Section {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
}

.Section__Service {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgb(233, 171, 255);
    padding: 12px 0;
}

.Section__Service a {
    padding: 7px 24px;
    display: inline-block;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 99px;
    margin: 5px;
    font-weight: 500;
    background-color: transparent;
}

.Section__Service a.active {
    background-color: white;
    border-color: white;
}

.Section__Service__Content>div {
    min-height: 70vh;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.Section__Service__Content>div:nth-child(even) {
    flex-direction: row-reverse;
}


@media only screen and (max-width: 768px) {
    .Section__Service a {
        padding: 4px 16px;
        font-size: 14px;
        line-height: 1.5em;
    }

    .Section__Service__Content h3 {
        font-size: 34px;
        margin-bottom: 12px;
    }

    .Section__Service__Content h5 {
        font-size: 18px;
    }

    .Section__Service__Content>div {
        min-height: 100vh;
        flex-direction: column;
    }
}

@media only screen and (max-width: 576px) {
    .Section__Service a {
        padding: 3px 12px;
        border-width: 1px;
        font-size: 12px;
        line-height: 1.5em;
        margin: 3px;
    }

    .Section__Service__Content h3 {
        font-size: 28px;
    }

    .Section__Service__Content h5 {
        font-size: 18px;
    }
}