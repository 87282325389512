.Payment__Section {
    padding: 100px 0;
    background-color: white;
}

.Payment__Item {
    text-align: center;
    padding: 0 15px;
}

.Payment__Item .Title {
    font-weight: 600;
}

.Payment__Item .Icon {
    margin-bottom: 24px;
}